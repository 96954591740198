angular.module('app.config').run(function ($rootScope, $state, UserService, $q, ACLService) {
  // global function for ACL check in angular template
  $rootScope.$scNeedPermissions = function (permissions) {
    return UserService.isUserLoggedIn()
      ? ACLService.hasPermissions(UserService.getUser(), permissions)
      : false;
  };

  $rootScope.$scNeedAnyPermissions = function (permissions) {
    return UserService.isUserLoggedIn()
      ? ACLService.hasPermissions(UserService.getUser(), permissions, /* needAllToPass */ false)
      : false;
  };

  $rootScope.$scHasAccountPermissions = function (permisison) {
    return UserService.isUserLoggedIn()
      ? ACLService.hasAccountPermissions(permisison, true)
      : false;
  };

  $rootScope.$scHasAnyAccountPermissions = function (permisison) {
    return UserService.isUserLoggedIn()
      ? ACLService.hasAccountPermissions(permisison, false)
      : false;
  };

  $rootScope.$scHasAccountRole = function (role) {
    let roles;

    if (UserService.isUserLoggedIn()) {
      roles = UserService.getActiveAccount().roles || [];
    } else {
      roles = [];
    }

    return roles.find((item) => item.name === role);
  };

  $rootScope.$scIsOrganizationOfType = function (orgType) {
    return UserService.isUserLoggedIn() ? ACLService.isOrganizationOfType(orgType) : false;
  };

  $rootScope.$scHasModule = function (moduleName) {
    const user = UserService.getUser();
    const organizationId = user.account._id;

    const trialPermission = `${moduleName}-trial`;

    const hasTrialPermission = organizationId
      ? ACLService.hasPermissions(user, `*:${organizationId}:${trialPermission}`)
      : ACLService.hasPermissions(user, `general:${trialPermission}`);

    const plans = _.get(user, 'subscription.subscription.items.data', []).map((item) => item.plan);

    return hasTrialPermission || plans.some((plan) => plan.metadata[moduleName] === 'true');
  };

  $rootScope.$on(
    '$stateChangeError',
    function (event, toState, toParams, fromState, fromParams, error) {
      if (error === 'ACCESS_DENIED') {
        $state.go('root.unauthorized');
      }
    },
  );
});
