angular.module('app.config').run(function ($rootScope, $state) {
  $rootScope.$on('$stateNotFound', function (event, request) {
    event.preventDefault();

    // TODO: log error

    if (/\({.+}\)$/.test(request.to)) {
      const [_, state, params] = /(.+)\(({.+})\)$/.exec(request.to);

      return $state.go(state, $rootScope.$eval(params), request.options);
    }

    $state.go('state-not-found');
  });
});
