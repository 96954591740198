angular.module('app.config').run(function ($rootScope, UserService) {
  const defaultStates = {
    country: 'root.platform.country',
    'country.organization': 'root.platform.country.organization',
    'organizations.companies': 'root.platform.companies',
    'organizations.company': 'root.platform.company',
    'organizations.unions': 'root.platform.unions',
    'organizations.union': 'root.platform.union',
    'organizations.scouting': 'root.platform.scouting_organization',
    competition: 'root.platform.competition.overview.teams',
    team: 'root.platform.team.overview.roster',
    player: 'root.platform.player.overview.statistics_by_game',
    countries: 'root.platform.countries',
    staff: 'root.platform.staff.overview',
    agencies: 'root.platform.agencies',
    agency: 'root.platform.agency',
    agent: 'root.platform.agent',

    home: 'root.platform.countries',
  };
  const defaultPrefixes = {
    country: 'Leagues of',
    'country.organization': '',
    'organizations.companies': '',
    'organizations.company': '',
    'organizations.unions': '',
    'organizations.scouting': '',
    'organizations.union': '',
    competition: 'Teams in',
    team: 'Roster of',
    player: 'Player',
    staff: '',
    countries: 'Home',
    agencies: 'Agencies',
    agency: 'Agency',
    agent: 'Agent',
    game: 'Game',

    home: 'Home',
  };

  $rootScope.getDefaultStateForModule = function (moduleName) {
    const state = defaultStates[moduleName];
    if (state === undefined) {
      throw new Error('State for module "' + moduleName + '" is not defined!');
    }

    if (moduleName === 'home' && UserService.getActiveAccount() && UserService.getUser()) {
      const isPlayerAccount = UserService.getActiveAccount().isPlayer;
      const isPlayerProfile = UserService.getUser().profile.type === 'player';
      const profileId = UserService.getUser().profile._id;

      if (isPlayerAccount && isPlayerProfile && profileId) {
        return `${defaultStates.player}({ id: '${profileId}' })`;
      }
    }

    return state;
  };

  $rootScope.getDefaultTitlePrefixForModule = function (moduleName) {
    const prefix = defaultPrefixes[moduleName];
    if (prefix === undefined) {
      throw new Error('Prefix for module "' + moduleName + '" is not defined!');
    }

    return prefix;
  };
});
