angular.module('app.config').run(function ($mdDateLocale, $filter, UserService) {
  const scDateFilter = $filter('scDate');

  $mdDateLocale.formatDate = function (value) {
    return scDateFilter(value) || '';
  };

  $mdDateLocale.parseDate = function (date) {
    const format = _.get(UserService.getUser(), 'settings.dateformat');
    const parsed = moment(date, format, true);

    return parsed.isValid() ? parsed.toDate() : new Date(NaN);
  };
});
