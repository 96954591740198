'use strict';

angular.module('app.config').run(function ($rootScope, $state, $location, RuntimeRequirements) {
  const ALLOWED_STATES = ['device.unsupported', 'device.tablet', 'device.mobile'];

  $rootScope.$on('$stateChangeStart', function (event, toState, toParams) {
    if (ALLOWED_STATES.indexOf(toState.name) >= 0) {
      return;
    }

    if (RuntimeRequirements.isMobile) {
      RuntimeRequirements.setReturnUrl($location.url());

      event.preventDefault();
      return $state.go('device.mobile');
    }

    if (RuntimeRequirements.isTablet && !RuntimeRequirements.isRecommendationIgnored) {
      RuntimeRequirements.setReturnUrl($location.url());

      event.preventDefault();
      return $state.go('device.tablet');
    }

    if (!RuntimeRequirements.isRecommended && !RuntimeRequirements.isRecommendationIgnored) {
      RuntimeRequirements.setReturnUrl($location.url());

      event.preventDefault();
      return $state.go('device.unsupported');
    }
  });
});
