'use strict';

angular
  .module('app.config')
  .run(
    function (
      $rootScope,
      $state,
      UserService,
      CommunicationMemberCache,
      CommunicationService,
      NotificationCenter,
    ) {
      $rootScope.$on('userDataUpdated', () => {
        CommunicationService.init();
      });

      $rootScope.$on('logout', () => {
        CommunicationService.destroy();
      });

      $rootScope.$on('communication:message:received', (_, {message, inboxKey}) => {
        if (!message.user_id || message.user_id === UserService.getUser()._id) {
          return;
        }

        const attachment = message.attachments && message.attachments[0];

        const promise = message.organization_id
          ? CommunicationMemberCache.getDisplayAndPicture('org', message.organization_id)
          : CommunicationMemberCache.getDisplayAndPicture('user', message.user_id);

        promise.then(({name, picture}) => {
          const title = name;
          const text =
            attachment && attachment.thumb_url
              ? 'New photo'
              : attachment
              ? `New attachment: ${attachment.name}`
              : message.text;

          const [inboxType, inboxId] = inboxKey.split(':');

          NotificationCenter.playSound();
          NotificationCenter.show({
            title,
            text,
            image: attachment && attachment.thumb_url,
            avatar: picture,
          }).then(() => {
            $state.go('root.communication.inbox.channel', {
              id: inboxType === 'user' ? 'private' : inboxId,
              channelId: message.channel_id,
            });
          });
        });
      });
    },
  );
