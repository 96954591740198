'use strict';
angular
    .module('app.config')
    .run(function ($rootScope, $state, UserService, CommunicationMemberCache, CommunicationService, NotificationCenter) {
    $rootScope.$on('userDataUpdated', function () {
        CommunicationService.init();
    });
    $rootScope.$on('logout', function () {
        CommunicationService.destroy();
    });
    $rootScope.$on('communication:message:received', function (_, _a) {
        var message = _a.message, inboxKey = _a.inboxKey;
        if (!message.user_id || message.user_id === UserService.getUser()._id) {
            return;
        }
        var attachment = message.attachments && message.attachments[0];
        var promise = message.organization_id
            ? CommunicationMemberCache.getDisplayAndPicture('org', message.organization_id)
            : CommunicationMemberCache.getDisplayAndPicture('user', message.user_id);
        promise.then(function (_a) {
            var name = _a.name, picture = _a.picture;
            var title = name;
            var text = attachment && attachment.thumb_url
                ? 'New photo'
                : attachment
                    ? "New attachment: ".concat(attachment.name)
                    : message.text;
            var _b = inboxKey.split(':'), inboxType = _b[0], inboxId = _b[1];
            NotificationCenter.playSound();
            NotificationCenter.show({
                title: title,
                text: text,
                image: attachment && attachment.thumb_url,
                avatar: picture,
            }).then(function () {
                $state.go('root.communication.inbox.channel', {
                    id: inboxType === 'user' ? 'private' : inboxId,
                    channelId: message.channel_id,
                });
            });
        });
    });
});