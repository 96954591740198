'use strict';

angular.module('app.config').run(function ($rootScope, UserService) {
  UserService.getPromise().then(() => updateTrackJsConfiguration());
  $rootScope.$on('user:account:change', () => updateTrackJsConfiguration());

  setInterval(() => updateTrackJsConfiguration(), 10000);

  function updateTrackJsConfiguration() {
    if (!window.trackJs) {
      window.trackJs = {
        configure: () => {},
        track: () => {},
      };
    }

    if (UserService.getUser()) {
      const user = UserService.getUser();
      const account = UserService.getActiveAccount();

      window.trackJs.configure({
        userId: `${user.profile.firstName} ${user.profile.lastName} (${user._id})`,
        sessionId: `${
          account.display || 'personal'
        }, admin: ${!!UserService.isLoggedInAsAdmin()}, invisible: ${!!UserService.isUserInvisible()}`,
      });
    }
  }
});
