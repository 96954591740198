angular.module('app.config').run(function ($rootScope, UserService, UserTasksHelper) {
  UserService.getPromise().then((user) => {
    if (user) {
      return runUserTaskHandlers(UserTasksHelper, user);
    }

    $rootScope.$on('userDataUpdated', () => {
      runUserTaskHandlers(UserTasksHelper, UserService.getUser());
    });
  });
} as any);

function runUserTaskHandlers(UserTasksHelper, user) {
  if (!user.tasks || (window as any).Cypress) {
    return;
  }

  const unconfirmedRepresentations = user.tasks['player:unconfirmed-representations'] || [];
  const representationConflicts = user.tasks['player:representation-conflicts'] || [];
  const newTosVersion = user.tasks['tos:new-tos-version'] || [];

  if (newTosVersion.length) {
    UserTasksHelper.handleTOSUpdate(newTosVersion[0]);
    return;
  }

  if (unconfirmedRepresentations.length > 0 || representationConflicts.length > 0) {
    UserTasksHelper.handleRepresentationChanges(
      unconfirmedRepresentations,
      representationConflicts,
    );
  }
}
