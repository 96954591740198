angular
  .module('app.config')
  .run(function ($q, $state, $injector, $rootScope, $location, UserService, CUSTOM_LOGOUT_HOST) {
    $rootScope.$userDetailsPromise = UserService.getPromise();

    const unsubscribe = $rootScope.$on('$stateChangeStart', function (event, toState, toParams) {
      const isUserLoggedIn = UserService.isUserLoggedIn();

      if (isStatePublic(toState)) {
        UserService.setReturnUrl($state.href(toState, toParams));
      }

      if (!isUserLoggedIn && !isStateService(toState)) {
        event.preventDefault();
        navigateToLoginPage();
        return $state.go('login.authenticator');
      }

      let redirectTo = toState.redirectTo;

      if (typeof redirectTo === 'function') {
        redirectTo = toState.redirectTo(toParams, $injector);
      }

      if (redirectTo) {
        event.preventDefault();
        return $state.go(redirectTo, toParams);
      }
    });

    // event broacasted from jwtInterceptor
    $rootScope.$on('unauthenticated', function () {
      $rootScope.$userDetailsPromise = $q.resolve();
      UserService.clearLocalSession();
      $rootScope.$broadcast('logout');
    });

    $rootScope.$on('logout', function () {
      if ($location.path() !== '/login') {
        navigateToLoginPage();
      }
    });

    $rootScope.$on('user:admin:logout', function () {
      if ($state.includes('admin')) {
        $state.go($rootScope.getDefaultStateForModule('home'));
      }
    });

    function navigateToLoginPage() {
      if (CUSTOM_LOGOUT_HOST) {
        window.location.href = CUSTOM_LOGOUT_HOST;
      } else {
        window.location.href = '/login';
      }
    }

    function isStatePublic(state) {
      return !isStateService(state) && state.name.indexOf('admin') !== 0;
    }

    function isStateService(state) {
      return state.name.indexOf('login') === 0 || state.name.indexOf('device') === 0;
    }
  });
